



























































import Vue from "vue";
import { mapState } from "vuex";
import { Toast } from "vant";
import { dispatch, ListQuery, UserCoupon } from "@/store";
import { CouponStatus } from "@/enum";

export default Vue.extend({
  data() {
    return {
      tabs: CouponStatus,
      loading: false,
      status: "unused",
      code: "",
    };
  },
  computed: {
    ...mapState("coupons", {
      list: "list",
      query: "query",
      count: "count",
    }),
    ...mapState(["settings"]),
    category(): string {
      const { type } = this.$route.params;
      switch (type) {
        case "am":
          return "early_bird";
        default:
          return "special_offer";
      }
    },
  },
  methods: {
    getList(query: ListQuery) {
      this.loading = true;
      dispatch
        .couponsGetUserCouponList({
          ...query,
          status: this.status,
          category: this.category,
        })
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    onRefresh() {
      this.getList({
        ...this.query,
        page_number: 1,
      });
    },
    onLoad() {
      this.getList({
        ...this.query,
        page_number: this.query.page_number + 1,
      });
    },
    onTabsChange(status: string) {
      this.status = status;
      this.$router.replace({
        path: this.$route.path,
        query: { status },
      });
      this.onRefresh();
    },
    onBindCode() {
      this.code.replace(/\s/g, "") &&
        dispatch
          .couponsBindCode({
            code: this.code.replace(/\s/g, ""),
            category: this.category,
          })
          .then(() => {
            Toast("领取成功");
            setTimeout(() => {
              this.onRefresh();
            }, 2000);
          })
          .catch((res: any) => {
            Toast.clear();
            switch (res.message) {
              case "not start":
                Toast(`领取失败，活动未开始`);
                break;
              case "already over":
                Toast(`领取失败，活动已结束`);
                break;
              case "ent: coupon_activity_code not found":
                Toast(`兑换码无效`);
                break;
              case "not allow to bind":
                Toast(`兑换码已被领取或失效`);
                break;
              case "category error":
                Toast(`兑换码类型错误，请核对确认`);
                break;
              default:
                Toast(`领取失败，请核对券码输入和类型是否正确`);
                break;
            }
          });
    },
    onCouponClick(userCoupon: UserCoupon) {
      const {
        validity_goods_type,
        validity_goods_type_ids,
      } = userCoupon.coupon.coupon_rule;
      if (validity_goods_type === "all") {
        this.$router.push(this.$paths.home);
      } else if (validity_goods_type_ids && validity_goods_type_ids.length) {
        const query: any = { ids: validity_goods_type_ids.join() };
        if (this.category === "early_bird") {
          query.user_coupon_id_am_code = userCoupon.id;
        }
        this.$router.push({
          path: this.$paths.products,
          query,
        });
      } else {
        this.$router.push(this.$paths.home);
      }
    },
  },
  watch: {
    $route(to) {
      if (this.status !== to.query.status) {
        this.status = to.query.status;
        this.onRefresh();
      }
    },
  },
  mounted() {
    document.title =
      this.category === "special_offer" ? "我的优惠券" : "必购码";
    this.status = String(this.$route.query.status || this.status);
    this.onRefresh();
    const coupon_code = this.$route.query.coupon_code;
    if (coupon_code) {
      this.code = coupon_code + "";
      this.onBindCode();
      this.code = "";
      this.$router.replace({
        path: this.$route.path,
        query: { status: this.status },
      });
    }
  },
});
